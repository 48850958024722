<template>
<div class="app-main__inner">
    <div class="row">
        <div class="col-md-12">
            <div class="app-page-title dashboard-title">
                <div class="page-title-wrapper">
                    <div class="page-title-heading">
                        <div class="page-title-icon">
                            <i class="pe-7s-help1 icon-gradient bg-happy-itmeo">
                            </i>
                        </div>
                        <div>Help (Q&A)
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
        <!--Accordion wrapper-->
        <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="false">
            <!-- I have a feedback  -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="headingOne1" >
                <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1" aria-expanded="true"
                aria-controls="collapseOne1" @click="clickTab('#headingOne1 a')">
                <h5 class="mb-0">
                  <i class="pe-7s-chat rotate-icon"></i>  I have a feedback <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="collapseOne1" class="collapse" role="tabpanel" aria-labelledby="headingOne1"
                data-parent="#accordionEx">
                <div class="card-body">
                    <div class="container contact-form">
                    <form @submit.prevent="saveUserMessageToDB">
                      <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" v-model="userMessage.first_name" name="txtfName" class="form-control" placeholder="Your First Name *" value="" />
                            </div>
                            <div class="form-group">
                                <input type="text" v-model="userMessage.last_name" name="txtlName" class="form-control" placeholder="Your Last Name" value="" />
                            </div>
                            <div class="form-group">
                                <input type="text" v-model="userMessage.email" name="txtEmail" class="form-control" placeholder="Your Email *" value="" />
                            </div>
                            <div class="form-group">
                                <input type="text" v-model="userMessage.mobileNumber" name="txtMobileNumber" class="form-control" placeholder="Your Mobile Number *" value="" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <textarea v-model="userMessage.message" maxlength="500" name="txtMsg" class="form-control" placeholder="Your Message *" style="width: 100%; height: 200px!important;"></textarea>
                            </div>
                        </div>
                        <div class="col">
                          <div class="form-group pull-right">
                              <input type="submit" name="btnSubmit" class="btn btn-success" value="Send Message" />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
            </div>
            <!-- Cannot record audio  -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="headingTwo2">
                <a class="collapsed" @click="clickTab('#headingTwo2 a')" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2"
                aria-expanded="false" aria-controls="collapseTwo2">
                <h5 class="mb-0">
                    Cannot record audio <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="collapseTwo2" class="collapse" role="tabpanel" aria-labelledby="headingTwo2"
                data-parent="#accordionEx">
                <div class="card-body ml-4 mr-2">
                    <ul>
                        <li>
                            Please use the latest Chrome browser <em> ( https://www.google.com/chrome ) </em>, to use our website, Download Chrome <em> here: https://www.google.com/chrome/ </em>
                        </li>
                        <li>
                            Check if your browser's microphone is turned on, by following this <em> https://support.google.com/chrome/answer/2693767?hl=en-GB </em>
                        </li>
                        <li>
                            Refresh the page by closing the tab and opening it again.
                        </li>
                        <li>
                        Check if your computer's other recording software works.
                        </li>
                        <li>
                            Check if audio recording works fine in Pearson's website <em> https://www.ptepractice.com/requirements</em>
                        </li>
                    </ul>
                </div>
            </div>
            </div>
            <!-- Why are there noises in audio recordings -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="headingThree2">
                <a class="collapsed" @click="clickTab('#headingThree2 a')" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree2"
                aria-expanded="false" aria-controls="headingThree2">
                <h5 class="mb-0">
                    Why are there noises in audio recordings ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="collapseThree2" class="collapse" role="tabpanel" aria-labelledby="headingThree2"
                data-parent="#accordionEx">
                 <div class="card-body ml-4 mr-2">
                    <ul>
                        <li>
                            This is most probably caused by your computer's high CPU usage, and you have opened too many computer softwares. Try to restart your computer, and close all other softwares except APEUni's webpage.
                        </li>
                        <li>
                            Confirm that the headset has its own noise.
                        </li>
                        <li>
                            Make sure the recording environment is quiet.
                        </li>
                        <li>
                            Make sure that the voltage of the computer's connected power supply is correct.
                        </li>
                    </ul>
                </div>
            </div>
            </div>
            <!-- Cannot submit answers  -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="headingFour2">
                <a class="collapsed" @click="clickTab('#headingFour2 a')" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour2"
                aria-expanded="false" aria-controls="headingFour2">
                <h5 class="mb-0">
                    Cannot submit answers <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="collapseFour2" class="collapse" role="tabpanel" aria-labelledby="headingFour2"
                data-parent="#accordionEx">
                <div class="card-body ml-4 mr-2">
                    <ul>
                        <li>
                            If it is an audio answer, check if the audio has any sound; a blank audio cannot be submitted.
                        </li>
                        <li>
                            Check your internet connection.
                        </li>
                        <li>
                            Try to logout and login again.
                        </li>
                        <li>
                            If problem still exist send us a msg our IT team will resolve your issue earliest possible.
                        </li>
                    </ul>
                </div>
            </div>
            </div>
            <!-- How to score speaking tasks  -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="headingFive2">
                <a class="collapsed" @click="clickTab('#headingFive2 a')" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive2"
                aria-expanded="false" aria-controls="headingFive2">
                <h5 class="mb-0">
                    How to score speaking tasks ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="collapseFive2" class="collapse" role="tabpanel" aria-labelledby="headingFive2"
                data-parent="#accordionEx">
                <div class="card-body">
                    <div class="card-body ml-4 mr-2">
                    <ul>
                        <li>
                            Speaking task can only submit if answer audio has proper sound; a blank audio cannot be submitted.
                        </li>
                        <li>
                            When finish recording, click submit button.
                        </li>
                        <li>
                            After submit, AI will start analysing your recorded answer and marking process will work.
                        </li>
                        <li>
                            Once the AI process done, AI-Result button will enable to view your results in prompt.
                        </li>
                         <li>
                                Click "Dict Mode" button for dictionary feature
                            </li>
                            <li>
                                On Dictionary prompt, double click on any word to read dictionary description.
                            </li>
                    </ul>
                    </div>
                </div>
            </div>
            </div>
            <!-- How to score writing tasks  -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="headingSix2">
                <a class="collapsed" @click="clickTab('#headingSix2 a')" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSix2"
                aria-expanded="false" aria-controls="headingSix2">
                <h5 class="mb-0">
                    How to score writing tasks ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="collapseSix2" class="collapse" role="tabpanel" aria-labelledby="headingSix2"
                data-parent="#accordionEx">
                <div class="card-body">
                   <div class="card-body ml-4 mr-2">
                    <ul>
                        <li>
                            Writing task can only submit if you entered text in the input field; a blank input field cannot be submitted.
                        </li>
                        <li>
                            When finish writing, click submit button.
                        </li>
                        <li>
                            After submit, AI will start analysing your answer and marking process will work.
                        </li>
                        <li>
                            Once the AI process done, AI-Result button will enable to view your results in prompt.
                        </li>
                         <li>
                                Click "Dict Mode" button for dictionary feature
                            </li>
                            <li>
                                On Dictionary prompt, double click on any word to read dictionary description.
                            </li>
                    </ul>
                    </div>
                </div>
            </div>
            </div>
            <!-- How to check answers of Reading and Listening questions  -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="headingSeven2">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseSeven2"
                aria-expanded="false" @click="clickTab('#headingSeven2 a')" aria-controls="headingSeven2">
                <h5 class="mb-0">
                    How to check answers of Reading and Listening questions ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="collapseSeven2" class="collapse" role="tabpanel" aria-labelledby="headingSeven2"
                data-parent="#accordionEx">
                <div class="card-body">
                    <div class="card-body ml-4 mr-2">
                        <ul>
                            <li>
                                Once you have completed the question, click “Submit” to check the answer and results.
                            </li>
                            <li>
                                Switch the “Answer” button to check the answer
                            </li>
                            <li>
                                Click "Dict Mode" button for dictionary feature
                            </li>
                            <li>
                                On Dictionary prompt, double click on any word to read dictionary description.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
            <!-- How to change password -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="headingEight2">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseEight2"
                aria-expanded="false" @click="clickTab('#headingEight2 a')" aria-controls="headingEight2">
                <h5 class="mb-0">
                    How to change password ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="collapseEight2" class="collapse" role="tabpanel" aria-labelledby="headingEight2"
                data-parent="#accordionEx">
                <div class="card-body">
                    <div class="card-body ml-4 mr-2">
                        <ul>
                            <li>
                                Open setting tab in dashboard view.
                            </li>
                            <li>
                                Enter new password and confirm password.
                            </li>
                            <li>
                                Incase of forget password, on login screen click forget password link.
                            </li>
                            <li>
                                Follow the instructions and enter you registered email address.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
            <!-- How to use PTE Learning hub -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="hlearning">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#clearning"
                aria-expanded="false" @click="clickTab('#hlearning a')" aria-controls="learning">
                <h5 class="mb-0">
                    How to use PTE Learning Hub ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="clearning" class="collapse" role="tabpanel" aria-labelledby="hlearning"
                data-parent="#accordionEx">
                <div class="card-body">
                    <div class="card-body ml-4 mr-2">
                        <b-overlay :show="showOverLap">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/0q01Xjwkk2o?si=p-psDcNPhALqPLqB" title="How to use PTE Learning Hub" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                   
                        <template #overlay>
                        <div class="text-center">
                        <b-icon icon="lock" font-scale="3" animation="cylon"></b-icon>
                    
                        <h4 class="mt-2" id="cancel-label">
                            For premium users</h4>
                        </div>
                        </template>
                        </b-overlay>

                    </div>
                </div>
            </div>
            </div>
            <!-- How to Subscribe Premium Package -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="hpackage">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#cpackage"
                aria-expanded="false" @click="clickTab('#hpackage a')" aria-controls="package">
                <h5 class="mb-0">
                    How to Subscribe Premium Package ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="cpackage" class="collapse" role="tabpanel" aria-labelledby="package"
                data-parent="#accordionEx">
                <div class="card-body">
                    <div class="card-body ml-4 mr-2">
                        <b-overlay :show="showOverLap">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/KN-K80_0h-g?si=0hohzziSC-XbWuEo" title="How to Subscribe Premium Package" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                   
                        <template #overlay>
                        <div class="text-center">
                        <b-icon icon="lock" font-scale="3" animation="cylon"></b-icon>
                    
                        <h4 class="mt-2" id="cancel-label">
                            For premium users</h4>
                        </div>
                        </template>
                        </b-overlay>

                    </div>
                </div>
            </div>
            </div>
            <!-- How to Reset the Password of the PTE Portal -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="hpassword">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#cpassword"
                aria-expanded="false" @click="clickTab('#hpassword a')" aria-controls="password">
                <h5 class="mb-0">
                    How to Reset the Password of the PTE Portal ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="cpassword" class="collapse" role="tabpanel" aria-labelledby="password"
                data-parent="#accordionEx">
                <div class="card-body">
                    <div class="card-body ml-4 mr-2">
                        <b-overlay :show="showOverLap">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/6UlDnL8k-Sk?si=PVBvkgIW6TXqFlH7" title="How to Reset the Password of the PTE Portal" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                   
                        <template #overlay>
                        <div class="text-center">
                        <b-icon icon="lock" font-scale="3" animation="cylon"></b-icon>
                    
                        <h4 class="mt-2" id="cancel-label">
                            For premium users</h4>
                        </div>
                        </template>
                        </b-overlay>

                    </div>
                </div>
            </div>
            </div>
            <!-- How to Look for PTE Classes Templates -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="htemplate">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#ctemplate"
                aria-expanded="false" @click="clickTab('#htemplate a')" aria-controls="learning">
                <h5 class="mb-0">
                    How to Look for PTE Classes Templates ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="ctemplate" class="collapse" role="tabpanel" aria-labelledby="learning"
                data-parent="#accordionEx">
                <div class="card-body">
                    <div class="card-body ml-4 mr-2">
                        <b-overlay :show="showOverLap">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/Zaob5CZovuU?si=2j8EjjVws6plqA9u" title="How to Look for PTE Classes Templates" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <template #overlay>
                        <div class="text-center">
                        <b-icon icon="lock" font-scale="3" animation="cylon"></b-icon>
                    
                        <h4 class="mt-2" id="cancel-label">
                            For premium users</h4>
                        </div>
                        </template>
                        </b-overlay>

                    </div>
                </div>
            </div>
            </div>
            <!-- How to Look for Advance Vocabulary Words -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="hvocab">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#cvocab"
                aria-expanded="false" @click="clickTab('#hvocab a')" aria-controls="learning">
                <h5 class="mb-0">
                    How to Look for Advance Vocabulary Words ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="cvocab" class="collapse" role="tabpanel" aria-labelledby="learning"
                data-parent="#accordionEx">
                <div class="card-body">
                    <div class="card-body ml-4 mr-2">
                        <b-overlay :show="showOverLap">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/KQwuXqF9CIU?si=F5kLE6rNCje8BS3T" title="How to Look for Advance Vocabulary Words" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <template #overlay>
                        <div class="text-center">
                        <b-icon icon="lock" font-scale="3" animation="cylon"></b-icon>
                    
                        <h4 class="mt-2" id="cancel-label">
                            For premium users</h4>
                        </div>
                        </template>
                        </b-overlay>

                    </div>
                </div>
            </div>
            </div>
            <!-- How to give a Feedback on PTE portal -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="hfeedback">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#cfeedback"
                aria-expanded="false" @click="clickTab('#hfeedback a')" aria-controls="learning">
                <h5 class="mb-0">
                    How to give a Feedback on PTE portal ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="cfeedback" class="collapse" role="tabpanel" aria-labelledby="learning"
                data-parent="#accordionEx">
                <div class="card-body">
                    <div class="card-body ml-4 mr-2">
                        <b-overlay :show="showOverLap">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/d9Ggb4l9dpI?si=dHEqZCBms0U-aZ0U" title="How to give a Feedback on PTE portal" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <template #overlay>
                        <div class="text-center">
                        <b-icon icon="lock" font-scale="3" animation="cylon"></b-icon>
                    
                        <h4 class="mt-2" id="cancel-label">
                            For premium users</h4>
                        </div>
                        </template>
                        </b-overlay>

                    </div>
                </div>
            </div>
            </div>
            <!-- How to Contact Us -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="hcontact">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#ccontact"
                aria-expanded="false" @click="clickTab('#hcontact a')" aria-controls="learning">
                <h5 class="mb-0">
                    How to Contact Us ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="ccontact" class="collapse" role="tabpanel" aria-labelledby="learning"
                data-parent="#accordionEx">
                <div class="card-body">
                    <div class="card-body ml-4 mr-2">
                        <b-overlay :show="showOverLap">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/xOOCxBlZnFs?si=aO2bAu3ahY0leQVs" title="How to Contact Us" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <template #overlay>
                        <div class="text-center">
                        <b-icon icon="lock" font-scale="3" animation="cylon"></b-icon>
                    
                        <h4 class="mt-2" id="cancel-label">
                            For premium users</h4>
                        </div>
                        </template>
                        </b-overlay>

                    </div>
                </div>
            </div>
            </div>
            <!-- How to Check Mock Test Results -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="hresult">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#cresult"
                aria-expanded="false" @click="clickTab('#hresult a')" aria-controls="learning">
                <h5 class="mb-0">
                    How to Check Mock Test Results ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="cresult" class="collapse" role="tabpanel" aria-labelledby="learning"
                data-parent="#accordionEx">
                <div class="card-body">
                    <div class="card-body ml-4 mr-2">
                        <b-overlay :show="showOverLap">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/xV_YFmFM-pI?si=QnhmGdv-e-r2PQd3" title="How to Check Mock Test Results" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <template #overlay>
                        <div class="text-center">
                        <b-icon icon="lock" font-scale="3" animation="cylon"></b-icon>
                    
                        <h4 class="mt-2" id="cancel-label">
                            For premium users</h4>
                        </div>
                        </template>
                        </b-overlay>

                    </div>
                </div>
            </div>
            </div>
            <!-- How Can we Get Help with Q&A for our Queries -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="hquery">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#cquery"
                aria-expanded="false" @click="clickTab('#hquery a')" aria-controls="learning">
                <h5 class="mb-0">
                    How Can we Get Help with Q&A for our Queries ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="cquery" class="collapse" role="tabpanel" aria-labelledby="learning"
                data-parent="#accordionEx">
                <div class="card-body">
                    <div class="card-body ml-4 mr-2">
                        <b-overlay :show="showOverLap">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/82L1Nlcdblc?si=kcETtpozQI-Kcryb" title="How Can we Get Help with Q&A for our Queries" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <template #overlay>
                        <div class="text-center">
                        <b-icon icon="lock" font-scale="3" animation="cylon"></b-icon>
                    
                        <h4 class="mt-2" id="cancel-label">
                            For premium users</h4>
                        </div>
                        </template>
                        </b-overlay>

                    </div>
                </div>
            </div>
            </div>
            <!-- Cannot solve your problem? contact us  -->
            <div class="card">
            <!-- Card header -->
            <div class="card-header" role="tab" id="heading10">
                <a data-toggle="collapse" data-parent="#accordionEx" href="#collapse10" aria-expanded="false"
                aria-controls="heading10" @click="clickTab('#heading10 a')">
                <h5 class="mb-0">
                  Cannot solve your problem? contact us ? <i class="fas fa-angle-down rotate-icon"></i>
                </h5>
                </a>
            </div>
            <!-- Card body -->
            <div id="collapse10" class="collapse" role="tabpanel" aria-labelledby="heading10"
                data-parent="#accordionEx">
                <div class="card-body">
                    <div class="container contact-form">
                    <form method="post">
                      <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="txtName" class="form-control" placeholder="Your Name *" value="" />
                            </div>
                            <div class="form-group">
                                <input type="text" name="txtEmail" class="form-control" placeholder="Your Email *" value="" />
                            </div>
                            <div class="form-group">
                                <input type="text" name="txtPhone" class="form-control" placeholder="Your Phone Number *" value="" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <textarea name="txtMsg" class="form-control" placeholder="Your Message *" maxlength="500" style="width: 100%; height: 200px!important"></textarea>
                            </div>
                        </div>
                        <div class="col">
                          <div class="form-group pull-right">
                              <input type="submit" name="btnSubmit" class="btn btn-success" value="Send Message" />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
            </div>
        </div>
        <!-- Accordion wrapper -->
        </div>
    </div>
</div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import $ from 'jquery'
import unirest from "unirest"
export default {
  data () {
    return {
        userMessage:{
        user_id:0,
        first_name:'',
        last_name:'',
        email:'',
        mobileNumber:'',
        message:'',
        url:'users/createUserMessage',
        showOverLap:false
      },
    }
  },
  methods:{
      clickTab(key){
          //console.log('Inside')
          $('.fa-angle-up').removeClass('fa-angle-up').addClass('fa-angle-down')
          $(key+' .fa-angle-down').addClass('fa-angle-up').removeClass('fa-angle-down')
      },
   ...mapActions({
            saveUserMessage:'auth/saveUserPersonalInfo'
        }),
        ...mapGetters({
            getUserMessages: 'auth/getPersonalInfo'
        }),
    saveUserMessageToDB(){
      if(this.userMessage.first_name.trim()==''| this.userMessage.message.trim()==''|this.userMessage.email.trim()==''|this.userMessage.mobileNumber.trim()==''){
        return this.$alert('Please fill all the required fields');
      }
      else{
        this.$alert('You message received, our Team will contact you shortly.')
        
        this.saveUserMessage(this.userMessage).then(()=>{
            this.sendUserMessage(this.userMessage.mobileNumber,this.userMessage.firstName,this.userMessage.userId);
            let melbOffice="+61435600610";
          this.sendMessageOffice(melbOffice,this.userMessage.firstName,this.userMessage.userId,this.userMessage.message);
          let pakOffice="+923303153327";
          this.sendMessageOffice(pakOffice,this.userMessage.firstName,this.userMessage.userId,this.userMessage.message);
          this.userMessage.message=''
        })
      }
    },
    sendUserMessage(mobileNumber,firstName,userId){
      var req = unirest('POST', 'https://emailservice.cyberleaf-solutions.com/api/clickSend/sendMessageByCallingApi')
                .headers({
                  'Content-Type': 'application/json'
                })
                .send(JSON.stringify({
                  "Client_Id": userId,
                  "Client_Name": firstName,
                  "toNumber": mobileNumber,
                  "messageBody": "Thank You! We have received your query and our concerned department will get back to you shortly",
                  "Purpose": "PTE User Query response",
                  "Delivery_Report": "Send Click not called yet.",
                  "DB_Saved_Status": "Not Saved yet."
                }))
                .end(function (res) { 
                  if (res.error) throw new Error(res.error); 
                  //console.log(res.raw_body);
                });
    },
    sendMessageOffice(mobileNumber,firstName,userId,msg){
      var req = unirest('POST', 'https://emailservice.cyberleaf-solutions.com/api/clickSend/sendMessageByCallingApi')
                .headers({
                  'Content-Type': 'application/json'
                })
                .send(JSON.stringify({
                  "Client_Id": userId,
                  "Client_Name": firstName,
                  "toNumber": mobileNumber,
                  "messageBody": msg,
                  "Purpose": "PTE Query to Office",
                  "Delivery_Report": "Send Click not called yet.",
                  "DB_Saved_Status": "Not Saved yet."
                }))
                .end(function (res) { 
                  if (res.error) throw new Error(res.error); 
                  //console.log(res.raw_body);
                });
    }
  },
  created(){
    //debugger
    const user=this.$ls.get('user')
    this.userMessage.user_id=user.user_id
    this.userMessage.first_name=user.first_name
    this.userMessage.last_name=user.last_name
    this.userMessage.email=user.email_id
    this.userMessage.mobileNumber=user.toNumber
  }
}
</script>
<style scoped>
h5.mb-0{
    font-size: 1.1rem;
}
</style>
